import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Pedido } from '../models/pedido';
import { MensajesService } from './mensajes.service';
import { Log } from '../models/log';
import { LogsService } from './logs.service';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {
  private url = 'pedidos';
  constructor(
    public afs: AngularFirestore,
    private msjs: MensajesService,
    private logsService: LogsService) { }

  agregarPedido(pedido: Pedido) {
    const ref = this.afs.collection<Pedido>(this.url);
    return ref.doc<Pedido>(pedido.nroPedido).set(Object.assign({}, pedido))
      .catch(error => {
        const log: Log = new Log();
        log.id = this.afs.createId();
        log.fecha = new Date().toLocaleString('es-CL');
        log.status = error.status;
        log.mensaje = error.message;
        log.descripcion = `Error al registrar el pedido ${pedido.nroPedido} en cloud.`;
        log.transportista = pedido.idTransportista;
        log.nroPedido = pedido.nroPedido;
        log.nroRuta = pedido.nroRuta;

        this.logsService.agregarLog(log).then(
          () => {
            this.msjs.mensajeError(
              'Lo sentimos',
              `Ha ocurrido un error al registrar el pedido en Cloud.`
            );
          },
          (err) => {
            console.warn(`No hemos podido registrar el error: ${err}`);
          }
        );
        return;
      });
  }

  async actualizarPedido(pedido: Pedido) {
    const ref = this.afs.collection<Pedido>(this.url);
    try {
      return await ref.doc(pedido.nroPedido).set(Object.assign({}, pedido));
    } catch (error) {
      const log: Log = new Log();
      log.id = this.afs.createId();
      log.fecha = new Date().toLocaleString('es-CL');
      log.status = error.status;
      log.mensaje = error.message;
      log.descripcion = `Error al actualizar el pedido ${pedido.nroPedido} en cloud.`;
      log.transportista = pedido.idTransportista;
      log.nroPedido = pedido.nroPedido;
      log.nroRuta = pedido.nroRuta;

      this.logsService.agregarLog(log).then(
        () => {
          this.msjs.mensajeError(
            'Lo sentimos',
            `Ha ocurrido un error al actualizar el pedido en Cloud.`
          );
        },
        (err) => {
          console.warn(`No hemos podido registrar el error: ${err}`);
        }
      );
      return;
    }
  }

  obtenerPedido(nroPedido: string) {
    const ref = this.afs.collection<Pedido>(this.url);
    return ref.doc(nroPedido).valueChanges();
  }

  listarPedidos() {
    return this.afs.collection<Pedido>(this.url).get();
  }

  listarPedidosPorRuta(nroRuta: string) {
    return this.afs.collection<Pedido>(
      this.url, ref => ref.where('nroRuta', '==', nroRuta).orderBy('estadoEntrega', 'desc')).valueChanges();
  }

  listarPedidosPorRutaActiva(nroRuta: string) {
    return this.afs.collection<Pedido>(
      // eslint-disable-next-line max-len
      this.url, ref => ref.where('nroRuta', '==', nroRuta)
        .where('estadoEntrega', 'not-in', ['Entregado', 'Entregado a tercero', 'Devolución'])).valueChanges();
  }

  listarDevoluciones() {
    return this.afs.collection<Pedido>(
      // eslint-disable-next-line max-len
      this.url, ref => ref.where('estadoEntrega', '==', 'Devolución')).valueChanges();
  }

  listarPedidosFinalizadosPorRuta(nroRuta: string) {
    return this.afs.collection<Pedido>(
      // eslint-disable-next-line max-len
      this.url, ref => ref.where('nroRuta', '==', nroRuta)
        .where('estadoEntrega', 'in', ['Entregado', 'Entregado a tercero'])).valueChanges();
  }

  listarPedidosFinalizados() {
    return this.afs.collection<Pedido>(
      this.url, ref => ref.where('estadoEntrega', 'in', ['Entregado', 'Entregado a tercero'])
        .orderBy('nroPedido', 'desc')).valueChanges();
  }

  listarPedidosEstadoTransportista(estadoEntrega: string, idTransportista: string) {
    return this.afs.collection<Pedido>(
      this.url, ref =>
      ref.where('estadoEntrega', '==', estadoEntrega)
      &&
      ref.where('idTransportista', '==', idTransportista)).valueChanges();
  }

  async actualizarEstadoPedido(nroPedido: string, estadoEntrega: string) {
    const ref = this.afs.collection(this.url).doc(nroPedido);
    try {
      await ref.set({ estadoEntrega }, { merge: true });
      console.log(`Se actualizo el estado del pedido ${nroPedido}`);
    } catch (err) {
      console.error(`Ha ocurrido un error, no hemos podido actualizar el estado del pedido \n ${err}`);
    }
  }

}
