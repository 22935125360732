import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Transportista } from '../models/usuario';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MensajesService } from './mensajes.service';
import { LogsService } from './logs.service';
import { Log } from '../models/log';

@Injectable({
  providedIn: 'root'
})
export class TransportistasService {
  private ref = this.afs.collection('transportistas');
  constructor(
    public afs: AngularFirestore,
    private http: HttpClient,
    private msjs: MensajesService,
    private logsService: LogsService
  ) { }

  async crearTransportista(transportista: Transportista) {
    try {
      return await this.ref.doc(transportista.idFirebase).set(Object.assign({}, transportista));
    } catch (error) {
      switch (error.code) {
        // case 'auth/email-already-in-use':
        //   this.msjs.mensajeError(
        //     'Email existente',
        //     'El email ingresado se encuentra registrado.'
        //   );
        //   break;

        default:
          this.msjs.mensajeError(
            'Ha ocurrido un error',
            'No hemos logrado registrar el/la transportista en el sistema.'
          );
          break;
      }

      const log: Log = new Log();
      log.id = this.logsService.afs.createId();
      log.fecha = new Date().toLocaleString('es-CL');
      log.status = error.code;
      log.mensaje = error.message;

      await this.logsService.agregarLog(log).then();
    }
  }

  actualizarTransportista(transportista: Transportista) {
    return this.ref.doc(transportista.idFirebase).update(Object.assign({}, transportista));
  }

  obtenerTransportista(idTransportista: string) {
    return this.ref.doc<Transportista>(idTransportista).valueChanges();
  }

  eliminarTransportista(idTransportista: string) {
    return this.ref.doc(idTransportista).delete();
  }

  listarTransportistas() {
    return this.ref.valueChanges();
  }

  async actualizarLicenciaTransportista(idTransportista: string, urlFoto: string) {
    const refTransportista = this.ref.doc(idTransportista);
    try {
      await refTransportista.set({ fotoLic: urlFoto }, { merge: true });
      console.log(`Se actualizo foto de licencia ${urlFoto}`);
      console.log('La foto de licencia del transportista ha sido actualizada');
    } catch (err) {
      console.error(`Ha ocurrido un error, no hemos podido actualizar la foto de licencia \n ${err}`);
    }
  }

  /**
   * API
   */

  listarTransportistasADR() {
    return this.http
      .get(`${environment.ipApiBackend}/transportistas/listar-transportistas`)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  obtenerTransportistaADR(idTransportista: string) {
    return this.http
      .get(`${environment.ipApiBackend}/transportistas/obtener-transportista?idTransportista=${idTransportista}`)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('Ha ocurrido un error:', error.error);
    } else {
      console.error(
        `Backend retorno error ${error.status}, Descripción: `, error.error);
    }
    return throwError(
      'Algo ha ocurrido; Por favor intenta más tarde.');
  }

}
