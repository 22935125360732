import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { RutaAdr } from '../models/ruta';

@Injectable({
  providedIn: 'root'
})
export class GestionAdrService {

  constructor(
    private http: HttpClient
  ) { }


  /**
   * Transportistas
   */

  listarTransportistasADR() {
    return this.http
      .get(`${environment.ipApiBackend}/transportistas/listar-transportistas`)
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerTransportistaADR(idTransportista: string) {
    return this.http
      .get(`${environment.ipApiBackend}/transportistas/obtener-transportista?idTransportista=${idTransportista}`)
      .pipe(
        catchError(this.handleError)
      );
  }


  /**
   * Rutas
   */
  obtenerRutasPorRevisar() {
    return this.http
      .get<RutaAdr[]>(`${environment.ipApiBackend}/rutas/obtener-rutas-admin`)
      .pipe(
        catchError(this.handleError)
      );
  }

  obtenerRuta(nroRuta: string) {
    return this.http
      .get<RutaAdr>(`${environment.ipApiBackend}/rutas/obtener-ruta?nroRuta=${nroRuta}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  listarPedidosRuta(nroRuta: string) {
    return this.http
      .get<RutaAdr>(`${environment.ipApiBackend}/rutas/listar-pedidos?nroRuta=${nroRuta}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('Ha ocurrido un error:', error.error);
    } else {
      console.error(
        `Backend retorno error ${error.status}, Descripción: `, error.error);
    }

    return throwError(
      'Algo ha ocurrido; Por favor intenta más tarde.');
  }
}
